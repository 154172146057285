import React from 'react';
import ProfilePhoto from '../assets/profile.jpg';
import './About.css';

import { Avatar, Fab, Grid, Grow } from '@material-ui/core';

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import DescriptionIcon from '@material-ui/icons/Description';

const styles = {
  profilePhoto: {
    width: "100%",
    height: "100%",
  }
}

class About extends React.Component {
  render() {
    return (
      <Grow in timeout={1000}>
        <div className="about">
          <Grid container direction="column" alignItems="center" justify="center">
            <Grid item>
              <div className="profile_photo">
                <Avatar alt="Ishaan Sharma" src={ProfilePhoto} style={styles.profilePhoto} />
              </div>
            </Grid>
            
            <Grid item>
              <h1 className="header">Ishaan&nbsp;
                <span className="secondary_header">Sharma</span>
              </h1>
            </Grid>

            <Grid item>
              <div className="subheading">
                New York Metropolitan Area
              </div>
            </Grid>

            <Grid item>
              <p className="lead">I'm <b>Ishaan Sharma</b> - a <b>Software Development Engineer II</b> working at <b>Audible</b>.</p>
            </Grid>

            <Grid item container direction="row" spacing={2} justify="center">
              <Grid item>
                <Fab color="primary" href="https://www.linkedin.com/in/ishaan99/" target="_blank" rel="noopener noreferrer" title="LinkedIn">
                  <LinkedInIcon />
                </Fab>
              </Grid>
              <Grid item>
                <Fab color="primary" href="https://github.com/ishaan99" target="_blank" rel="noopener noreferrer" title="GitHub">
                  <GitHubIcon />
                </Fab>
              </Grid>
              <Grid item>
                <Fab color="primary" href="https://twitter.com/IshaanS99" target="_blank" rel="noopener noreferrer" title="Twitter">
                  <TwitterIcon />
                </Fab>
              </Grid>
              <Grid item>
                <Fab color="primary" href="https://www.facebook.com/IshaanS99" target="_blank" rel="noopener noreferrer" title="Facebook">
                  <FacebookIcon />
                </Fab>
              </Grid>
              <Grid item>
                <Fab color="primary" href="IshaanSharmaResume.pdf" target="_blank" rel="noopener noreferrer" title="Resume">
                  <DescriptionIcon />
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grow>
    );
  }
}

export default About;