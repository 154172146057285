import React from 'react';

class Interests extends React.Component {
  render() {
    return (
      <section class="resume-section p-3 p-lg-5 d-flex flex-column">
        <div class="my-auto">
          <h2 class="mb-5">Interests</h2>
          <p>I enjoy...</p>
          <p class="mb-0">...</p>
        </div>
      </section>
    );
  }
}

export default Interests;