import React from 'react';
import { Grid, Grow } from '@material-ui/core';
import './Education.css';

class Education extends React.Component {
  render() {
    return (
      <Grow in timeout={1000}>
        <div className="education">
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <h1>Education</h1>
            </Grid>

            {/* University of Toronto */}
            <Grid item container direction="row" wrap="nowrap">
              <Grid item xs={1}>
                <div></div>
              </Grid>
              <Grid item container direction="column" spacing={1}>
                <Grid item container direction="column" spacing={0}>
                  <Grid item container direction="row" justify="space-between">
                    <Grid item>
                        <h3><b>University of Toronto</b></h3>
                    </Grid>
                    <Grid item>
                      <span className="text-primary">September 2017 - Dec 2021</span>
                    </Grid>
                  </Grid>

                  <Grid item container direction="row" justify="space-between">
                    <Grid item>
                      <h4 className="title"><i>Bachelor of Science (Honors) - Computer Science Specialist</i></h4>
                    </Grid>
                    <Grid item>
                      <span className="text-primary">Toronto, ON, Canada</span>
                    </Grid>
                  </Grid>
                </Grid>
                
                <Grid item container direction="row">
                  <Grid item xs={1}>
                    <div></div>
                  </Grid>
                  <Grid item>
                    <div>
                      <p>
                        4th-year (Senior)<br/>
                        Computer Science Courses Taken:
                      </p>
                      <ul>
                        <li>CSC108 - Introduction to Computer Programming</li>
                        <li>CSC148 - Introduction to Computer Science</li>
                        <hr/>
                        <li>CSC207 - Software Design</li>
                        <li>CSC209 - Software Tools and Systems Programming</li>
                        <li>CSC236 - Introduction to the Theory of Computation</li>
                        <li>CSC258 - Computer Organization</li>
                        <li>CSC263 - Data Structures and Analysis</li>
                        <hr/>
                        <li>CSC301 - Introduction to Software Engineering</li>
                        <li>CSC309 - Programming on the Web</li>
                        <li>CSC324 - Principles of Programming</li>
                        <li>CSC343 - Introduction to Databases</li>
                        <li>CSC358 - Principles of Computer Networks</li>
                        <li>CSC363 - Computational Complexity and Computability</li>
                        <li>CSC369 - Operating Systems</li>
                        <li>CSC373 - Algorithm Design and Analysis</li>
                        <li>CSC384 - Introduction to Artificial Intelligence</li>
                        <hr/>
                        <li>CSC492 - Computer Science Implementation Project Predictive and Prescriptive Analytics for Financial Market Solutions [M. Liut]</li>
                        <li>CSC493 - Computer Science Expository Work</li>
                      </ul>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Rick Hansen */}
            <Grid item container direction="row" wrap="nowrap">
              <Grid item xs={1}>
                <div></div>
              </Grid>
              <Grid item container direction="column" spacing={1}>
                <Grid item container direction="column" spacing={0}>
                  <Grid item container direction="row" justify="space-between">
                    <Grid item>
                      <h3><b>Rick Hansen Secondary School</b></h3>
                    </Grid>
                    <Grid item>
                      <span className="text-primary">September 2013 - June 2017</span>
                    </Grid>
                  </Grid>

                  <Grid item container direction="row" justify="space-between">
                    <Grid item>
                      <h4 className="title"><i>High School</i></h4>
                    </Grid>
                    <Grid item>
                      <span className="text-primary">Mississauga, ON, Canada</span>
                    </Grid>
                  </Grid>
                </Grid>
                
                <Grid item container direction="row">
                  <Grid item xs={1}>
                    <div></div>
                  </Grid>
                  <Grid item>
                    <div>
                      <p>
                        Related Courses:
                      </p>
                      <ul>
                        <li>TIJ1O0 - Exploring Technologies</li>
                        <hr/>
                        <li>ICS2O0 - Introduction to Computer Studies</li>
                        <li>TEJ2O0 - Computer Technology</li>
                        <hr/>
                        <li>TMR3MH - Man. Eng. Technology: Robotics and Control Systems</li>
                        <li>ICS3UH - Introduction to Computer Science</li>
                        <li>TEJ3MH - Computer Engineering Technology</li>
                        <hr/>
                        <li>ICS4U0 - Computer Science</li>
                      </ul>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grow>
    );
  }
}

export default Education;