import React from 'react';
import { useMediaQuery } from 'react-responsive'
import { Grid } from '@material-ui/core';
import './Experience.css';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1083 })
  return isDesktop ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1082 })
  return isMobile ? children : null
}

class ExperienceItem extends React.Component {

  horizontalView = (
    <Grid item container direction="row" spacing={1}>
      {/* Logo */}
      <Grid item container direction="column" xs={1} alignContent="center">
        <Grid item>
          <img width="56px" height="56px" src={this.props.logo} alt={this.props.logoAltText}/>
        </Grid>
      </Grid>

      {/* Description */}
      <Grid item container direction="column" xs>
        <Grid item>
          <h4><b>{this.props.company}</b></h4>
        </Grid>

        <Grid item>
          <h4 className="title"><i>{this.props.title}, {this.props.team}</i></h4>
        </Grid>
        
        <Grid item>
          {this.props.children}
        </Grid>
      </Grid>

      {/* Location/Date */}
      <Grid item container direction="column" xs={2} alignContent="flex-end" alignItems="flex-end">
          <Grid item>
            <span className="text-primary">{this.props.location}</span>
          </Grid>
          <Grid item>
            <span className="text-primary">{this.props.dates}</span>
          </Grid>
      </Grid>
    </Grid>
  )
  
  verticalView = (
    <Grid item container direction="column" spacing={1}>      
      <Grid item container direction="row" alignContent="flex-start" alignItems="center" spacing={1}>
        <Grid item>
            <img width="56px" height="56px" src={this.props.logo} alt={this.props.logoAltText}/>
        </Grid>

        <Grid item xs={9}>
          <h3><b>{this.props.company}</b></h3>
          <h4 className="title"><i>{this.props.title}, {this.props.team}</i></h4>
        </Grid>
      </Grid>

      <Grid item container direction="row" justify="space-between">
        <Grid item><span className="text-primary">{this.props.location}</span></Grid>
        <Grid item><span className="text-primary">{this.props.dates}</span></Grid>
      </Grid>
        
      <Grid item>
        {this.props.children}
      </Grid>
    </Grid>
  )

  render() {
    return (
      <React.Fragment>
        <Mobile>{this.verticalView}</Mobile>
        <Desktop>{this.horizontalView}</Desktop>
      </React.Fragment>
    )
  }
}

export default ExperienceItem;