import React from 'react';
import { Grid, Grow } from '@material-ui/core';
import ExperienceItem from './ExperienceItem';
import './Experience.css';
import Coursera from '../assets/coursera.jpg';
import IBM from '../assets/ibm.png';
import AWS from '../assets/aws.jpg';
import FIRSTRobotics from '../assets/first_robotics.png';
import ProjectItem from './ProjectItem';
import Audible from '../assets/audible.jpg';

class Experience extends React.Component {

  render() {
    return (
      <Grow in timeout={1000}>
        <div className="experience">
          <Grid container direction="column" spacing={2}>

            <h1>Experience</h1>

            <ExperienceItem 
              logo={Audible} 
              logoAltText="Audible logo" 
              company="Audible (Amazon)" 
              title="Software Development Engineer II" 
              team="Library Backend Team" 
              location="New York, USA" 
              dates="Jan 2022 - Present">
                <ul>
                  <li>Developing backend API services in <b>Java</b> for the Audible Library page</li>
                </ul>
            </ExperienceItem>

            <ExperienceItem 
              logo={AWS} 
              logoAltText="AWS logo" 
              company="Amazon Web Services" 
              title="Software Development Engineer Intern" 
              team="AWS RDS for Oracle" 
              location="Vancouver, Canada" 
              dates="May 2021 - Aug 2021">
                <ul>
                  <li>Added features to <b>AWS Relational Database Service for Oracle</b>'s S3 Integration in <b>Java</b> and <b>PL/SQL</b></li>
                </ul>
            </ExperienceItem>

            <ExperienceItem 
              logo={Coursera} 
              logoAltText="Coursera logo" 
              company="Coursera" 
              title="Software Engineering Intern" 
              team="Content Ingestion Team" 
              location="Toronto, Canada" 
              dates="Aug 2020 - Dec 2020">
                <ul>
                  <li>Designed and developed a new API in <b>Scala</b> for partners to easily build and transfer course content from other course providers</li>
                </ul>
            </ExperienceItem>

            <ExperienceItem 
              logo={IBM} 
              logoAltText="IBM logo" 
              company="IBM" 
              title="Software Engineer Intern" 
              team="Db2 Hybrid Data Management" 
              location="Toronto, Canada" 
              dates="May 2020 - Aug 2020">
                <ul>
                  <li>Created web dashboard to view <b>Db2</b> service data and test metrics of different platform builds and versions in <b>JavaScript</b>, <b>Node.js</b>, <b>Express.js</b>, and <b>AngularJS</b></li>
                  <li>Decreased script runtimes by 80% to filter/merge Db2 high availability logs and tests on <b>Linux</b> and <b>AIX</b> machines</li>
                  <li>Conducted network and hardware failure tests by creating scripts to bring database hosts down and catch failures</li>
                </ul>
            </ExperienceItem>

            <ExperienceItem 
              logo={AWS} 
              logoAltText="AWS logo" 
              company="Amazon Web Services" 
              title="Software Development Engineer Intern" 
              team="AWS Orchestration Platform Team" 
              location="Vancouver, Canada" 
              dates="Sep 2019 - Nov 2019">
                <ul>
                  <li>Developed a service for AWS CloudFormation in <b>Java</b> that allows provisioning internal and native AWS resources across all regions and accounts</li>
                  <li>Added temporary credentials using AWS Security Token Service and created unit and integration tests</li>
                  <li>Wrote a template in <b>Ruby</b> to build and manage the deployment pipeline which automatically ran unit and integration tests and deployed the service on multiple AWS Availability Zones</li>
                  <li>Achieved goals of deploying to production and having customers utilize the service</li>
                  <li>Object-oriented design and utilized numerous design patterns</li>
                </ul>
            </ExperienceItem>

            <ExperienceItem 
              logo={FIRSTRobotics} 
              logoAltText="FIRST Robotics logo" 
              company="FIRST & VEX Robotics High School Team" 
              title="Programmer" 
              team={<span><a href="https://www.firstinspires.org/robotics/frc" target="_blank" rel="noopener noreferrer">firstinspires.org</a>,&nbsp; 
                    <a href="https://www.theory6.ca/" target="_blank" rel="noopener noreferrer">theory6.ca</a></span>}
              location="Mississauga, Canada" 
              dates="Sept 2013 - June 2017">
                <ul>
                    <li>Manufactured, designed, and programmed competitive robots in two-month periods</li>
                    <li>Programmed autonomous route for robot using <b>Java</b> and <b>C</b> by using real-time sensor data</li>
                    <li>Implemented a PID controller to continuously fine-tune operations and maneuvers with precision</li>
                    <li>Thought-out and designed each component of the robot in <b>CAD</b> (Computer-aided design software)</li>
                    <li>Robot Code: <a href="https://github.com/Ishaan99/FRC-2017-1241-SW" target="_blank" rel="noopener noreferrer">github.com/Ishaan99/FRC-2017-1241-SW</a></li>
                </ul>
            </ExperienceItem>

            <h1>Projects</h1>

            <ProjectItem
              name="Fortnite 2D Web Application"
              dates="March 2020 - April 2020">
                <ul>
                  <li>Developed a 2D version of Fortnite using a Node.js and Express.js backend and a React frontend</li>
                  <li>Utilized Canvas for game UI and added support for multiplayer via WebSockets and AI bots</li>
                  <li>Created a user management system with a REST API using Ajax and stored data in an SQLite database</li>
                  <li>Built the application for web and mobile platforms by allowing touch and gyroscope inputs</li>
                </ul>
            </ProjectItem>

            <ProjectItem
              name="Driveway Parking Sharing App"
              dates="Feb 2020 - March 2020">
                <ul>
                  <li>Developed parking sharing app using a React and Redux front-end to sell and find parking</li>
                  <li>Used microservice development to split up a parking spot system and chat system</li>
                  <li>Displayed parking locations using Google MAPS API and used HERE API to autocomplete addresses</li>
                  <li>Stored posts in a Firebase Cloud Firestore database and chat messages in a Firebase Realtime database </li>
                  <li>Worked and communicated as part of a team using Git workflow and scrum methodology</li>
                </ul>
            </ProjectItem>

            <ProjectItem
              name="Artificial Intelligence Pacman Game Solver"
              dates="June 2019 - July 2019">
                <ul>
                  <li>Created a pacman game solver in Python with varying difficulties by enforcing heuristics</li>
                  <li>Compared multiple decision rules such as Minimax, Alpha-Beta Pruning, and Expectimax Search</li>
                  <li>Enhanced pacmans precision by using inferences created by pacman’s observations</li>
                </ul>
            </ProjectItem>

            <ProjectItem
              name="Backend Task Management Service"
              dates="Feb 2019 - March 2019">
                <ul>
                  <li>Built a server program in C that implements a task management protocol</li>
                  <li>Accepted clients to connect to the server using TCP ports, pipes, and sockets and let them run tasks</li>
                  <li>Discovered and solved vulnerabilities to safeguard the service and prevent unauthorized access</li>
                  <li>Tasks were able to be run concurrently, and clients were able to run, kill, watch, and list all the jobs running</li>
                </ul>
            </ProjectItem>

            <ProjectItem
              name="Paint Application"
              dates="Oct 2018 - Nov 2018">
                <ul>
                  <li>Programmed an object-oriented paint application using Java</li>
                  <li>Developed as part of a team using scrum methodology with an agile approach</li>
                  <li>Implemented numerous design patterns and increased use of unit tests</li>
                  <li>Improved build management by utilizing Git to take advantage of its version control</li>
                  <li>Allowed users to create drawings using lines/shapes and edit their color and size</li>
                  <li><a href="https://github.com/Ishaan99/PaintApp" target="_blank" rel="noopener noreferrer">Paint App</a></li>
                </ul>
            </ProjectItem>

            <ProjectItem
              name="Calculus & Vectors Help Application"
              dates="Nov 2017 - Dec 2017">
                <ul>
                  <li>Created using Model-View-Controller design pattern and object-oriented programming in Java</li>
                  <li>Helps teach the fundamental concepts of derivatives and vectors for students in high school Calculus</li>
                  <li>The derivative calculator uses an interactive keyboard layout which shows/hides characters based on input</li>
                  <li>The vector calculator shows the users inputted vectors on a graph with the result </li>
                  <li><a href="https://github.com/Ishaan99/CalcKit" target="_blank" rel="noopener noreferrer">CalcKit</a></li>
                </ul>
            </ProjectItem>

            <ProjectItem
              name="Alexa Skills: Questions and Answers, Live Stock Information"
              dates="July 2017 - Aug 2017">
                <ul>
                  <li>Created two Amazon Alexa skills which reply to voice commands using intents and utterances</li>
                  <li>Used Javascript to fetch stock data from APIs and reply using the JSON responses</li>
                </ul>
            </ProjectItem>

          </Grid>
        </div>
      </Grow>
    );
  }
}

export default Experience;