import React from 'react';
import './Skills.css';

import { Grid, Grow } from '@material-ui/core';
import { FaPython, FaJava, FaCss3Alt, FaCheck } from 'react-icons/fa';
import { DiSqllite, DiPhp } from 'react-icons/di';
import { IoLogoJavascript } from 'react-icons/io';
import { TiHtml5 } from 'react-icons/ti';


class Skills extends React.Component {
  render() {
    return (
      <div className="skills">
        <Grow in timeout={1000}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <h2>Skills</h2>
            </Grid>

            <Grid item container direction="row" wrap="nowrap">
              <Grid item xs={1}>
                <div></div>
              </Grid>
              <Grid item container direction="column" spacing={1}>
                <Grid item>
                  <h5>Programming Languages &amp; Tools</h5>
                </Grid>
                <Grid item container direction="row" spacing={2}>
                  <Grid item>
                    <FaPython size={50} title="Python"/>
                  </Grid>
                  <Grid item>
                    <FaJava size={50} title="Java"/>
                  </Grid>
                  <Grid item>
                    <div title="C">C</div>
                  </Grid>
                  <Grid item>
                    <DiSqllite size={50} title="SQL"/>
                  </Grid>
                  <Grid item>
                    <IoLogoJavascript size={50} title="Javascript"/>
                  </Grid>
                  <Grid item>
                    <div title="C++">C++</div>
                  </Grid>
                  <Grid item>
                    <TiHtml5 size={50} title="HTML"/>
                  </Grid>
                  <Grid item>
                    <FaCss3Alt size={50} title="CSS"/>
                  </Grid>
                  <Grid item>
                    <DiPhp size={50} title="PHP"/>
                  </Grid>
                  <Grid item>
                    <div title="VB">VB</div>
                  </Grid>
                </Grid>

                <Grid item>
                  <h5>Workflow</h5>
                </Grid>
                <Grid item container direction="column">
                  <Grid item>
                    <FaCheck /> Unit Testing, Regular Expressions
                  </Grid>
                  <Grid item>
                    <FaCheck /> Object-oriented Programming &amp; Design Patterns
                  </Grid>
                  <Grid item>
                    <FaCheck /> Git &amp; Version Control Software
                  </Grid>
                  <Grid item>
                    <FaCheck /> Agile Development &amp; Scrum
                  </Grid>
                  <Grid item>
                    <FaCheck /> Testing &amp; Debugging
                  </Grid>
                  <Grid item>
                    <FaCheck /> Cross Functional Teams
                  </Grid>
                  <Grid item>
                    <FaCheck /> PhotoShop &amp; Graphic Design
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grow>
      </div>
    );
  }
}

export default Skills;