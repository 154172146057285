import React from 'react';
import { useMediaQuery } from 'react-responsive'
import { Grid } from '@material-ui/core';
import './Experience.css';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1083 })
  return isDesktop ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1082 })
  return isMobile ? children : null
}

class ProjectItem extends React.Component {

  horizontalView = (
    <Grid item container direction="row" wrap="nowrap" spacing={1}>
      <Grid item container xs={1}>
        <div></div>
      </Grid>

      <Grid item container direction="column" spacing={0} xs>
        <Grid item container direction="row" justify="space-between">
          <Grid item>
            <h4><b>{this.props.name}</b></h4>
          </Grid>
          <Grid item>
            <span className="text-primary">{this.props.dates}</span>
          </Grid>
        </Grid>
        
        <Grid item>
          {this.props.children}
        </Grid>
      </Grid>
    </Grid>
  )
  
  verticalView = (
    <Grid item container direction="column" spacing={0} xs>
      <Grid item>
        <h4><b>{this.props.name}</b></h4>
      </Grid>

      <Grid item container justify="flex-end">
        <span className="text-primary">{this.props.dates}</span>
      </Grid>
      
      <Grid item>
        {this.props.children}
      </Grid>
    </Grid>
  )

  render() {
    return (
      <React.Fragment>
        <Mobile>{this.verticalView}</Mobile>
        <Desktop>{this.horizontalView}</Desktop>
      </React.Fragment>
    )
  }
}

export default ProjectItem;