import React from 'react';
import { useMediaQuery } from 'react-responsive'
import ProfilePhoto from '../assets/profile.jpg';
import { Avatar, Button, Fade, Grid } from '@material-ui/core';

import './Nav.css';

const styles = {
  profilePhoto: {
    width: "100%",
    height: "100%",
  }
}

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1083 })
  return isDesktop ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1082 })
  return isMobile ? children : null
}

class Nav extends React.Component {
  render() {
    let showProfileImg = this.props.page !== "About";

    let horizontalView = (
      <div className="nav">
        <Fade in timeout={1000}>
          <Grid container direction="row" justify="space-evenly" alignContent="center" alignItems="center">
            <Grid item>
              <Button name="About" variant="outlined" color="primary" onClick={this.props.handleNavClick} >
                About
              </Button>
            </Grid>
            <Grid item>
              <Button name="Education" variant="outlined" color="primary" onClick={this.props.handleNavClick} >
                Education
              </Button>
            </Grid>

            {showProfileImg && (
              <div className="nav_center_profile">
                <Grid item>
                  <Grid item container direction="column" spacing={1} justify="center" alignItems="center">
                    <Grid item>
                      <div className="nav_profile_photo">
                        <Avatar alt="Ishaan Sharma" src={ProfilePhoto} style={styles.profilePhoto} />
                      </div>
                    </Grid>
                    <Grid item>
                      <h1 className="nav_header">Ishaan&nbsp;
                        <span className="nav_secondary_header">Sharma</span>
                      </h1>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
            
            <Grid item>
              <Button name="Experience" variant="outlined" color="primary" onClick={this.props.handleNavClick} >
                Experience
              </Button>
            </Grid>
            <Grid item>
              <Button name="Skills" variant="outlined" color="primary" onClick={this.props.handleNavClick} >
                Skills
              </Button>
            </Grid>
          </Grid>
        </Fade>
      </div>
    )

    let verticalView = (
      <div className="nav">
        <Fade in timeout={1000}>
          <Grid container direction="column" spacing={2} justify="space-evenly" alignContent="center" alignItems="center">

            {showProfileImg && (
              <div className="nav_center_profile">
                <Grid item>
                  <Grid item container direction="column" spacing={1} justify="center" alignItems="center">
                    <Grid item>
                      <div className="nav_profile_photo">
                        <Avatar alt="Ishaan Sharma" src={ProfilePhoto} style={styles.profilePhoto} />
                      </div>
                    </Grid>
                    <Grid item>
                      <h1 className="nav_header">Ishaan&nbsp;
                        <span className="nav_secondary_header">Sharma</span>
                      </h1>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}

            <Grid item container direction="row" spacing={2} alignItems="center" justify="center">
              <Grid item>
                <Button name="About" variant="outlined" color="primary" onClick={this.props.handleNavClick} >
                  About
                </Button>
              </Grid>

              <Grid item>
                <Button name="Education" variant="outlined" color="primary" onClick={this.props.handleNavClick} >
                  Education
                </Button>
              </Grid>     
            </Grid>         

            <Grid item container direction="row" spacing={2} alignItems="center" justify="center">
              <Grid item>
                <Button name="Experience" variant="outlined" color="primary" onClick={this.props.handleNavClick} >
                  Experience
                </Button>
              </Grid>
              
              <Grid item>
                <Button name="Skills" variant="outlined" color="primary" onClick={this.props.handleNavClick} >
                  Skills
                </Button>
              </Grid>
            </Grid>       
          </Grid>
        </Fade>
      </div>
    )

    return (
      <React.Fragment>
        <Mobile>{verticalView}</Mobile>
        <Desktop>{horizontalView}</Desktop>
      </React.Fragment>
    );
  }
}

export default Nav;