import React from 'react';
import './App.css';

import Nav from './components/Nav';
import About from './components/About';
import Education from './components/Education';
import Experience from './components/Experience';
import Skills from './components/Skills';
import Interests from './components/Interests';

class App extends React.Component {
  state = {
    page: "About",
  }

  handleNavClick = e => {
    this.setState({
      page: e.currentTarget.name,
    })
  }

  render() {
    var page;
    switch (this.state.page) {
      case "About":
        page = <About/>;
        break;
      case "Education":
        page = <Education/>;
        break;
      case "Experience":
        page = <Experience/>;
        break;
      case "Skills":
        page = <Skills/>;
        break;
      case "Interests":
        page = <Interests/>;
        break;
      default:
        page = <div>Error 404</div>;
        break;
    }

    return (
      <div>
        <header className="App-header">
          <Nav handleNavClick={this.handleNavClick} page={this.state.page}/>
          <div className="page">
            {page}
          </div>
        </header>
      </div>
    );
  }
}

export default App;
